import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Check } from '@alicorpdigital/dali-components-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { Form, FormGroup } from '@insuma/mpp-ui/components/form';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { customizationRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import { ESuggestion, ESuggestionLabel } from 'core/model/enums/suggested-product.enum';
import type { ICustomizationSuggestedProductFormData } from 'core/model/interfaces/themes';
import { InputValueForm } from 'shared/components/forms';
import { Link } from 'shared/components/link';
import { suggestedProductFormSchema } from './suggested-product-form.schema';

import './suggested-product-form.scss';

interface ISuggestedProductFormProps {
  isSaving?: boolean;
  onSubmit: (data: ICustomizationSuggestedProductFormData) => void;
  formData: ICustomizationSuggestedProductFormData;
}

export const SuggestedProductForm = ({ isSaving, onSubmit, formData }: ISuggestedProductFormProps) => {
  const css = useCSS('customization-suggested-product-form');
  const { customizationId } = useParams();
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(suggestedProductFormSchema),
    defaultValues: formData,
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = formMethods;

  return (
    <div className={css()}>
      <div className={css('back')}>
        <Link icon="caret-left" to={`${themesRoutePath}${customizationRoutePath}/${customizationId}/modulos`}>
          Atrás
        </Link>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h2 className={css('title')}>Producto Sugerido</h2>
        <br />

        <div>
          <h4 className={css('subtitle')}>Carrusel personalizado</h4>
          <div className="row-container">
            <h4 className={css('subtitle')}>1. Configuración de Sugerencias en el Homepage</h4>
            <div className={css('section')}>
              <InputValueForm
                register={register}
                isInvalid={!!errors.title}
                helperText={errors.title?.message}
                label="Título de sugerencia"
                name="title"
                placeholder="Título de sugerencia"
              />
              <InputValueForm
                register={register}
                isInvalid={!!errors.maxSuggestions}
                helperText={errors.maxSuggestions?.message}
                label="N° máximo de sugerencias"
                name="maxSuggestions"
                placeholder="N° máximo de sugerencias"
              />
            </div>
          </div>
          <br />
          <div className="row-container">
            <h4 className={css('subtitle')}>2. Elegir opción a mostrar</h4>

            <FormGroup>
              <Check
                direction="vertical"
                id={`type-${ESuggestion.UNFORGIVABLES}`}
                type="radio"
                value={ESuggestion.UNFORGIVABLES}
                {...register(`type`)}
              >
                {ESuggestionLabel.UNFORGIVABLES}
              </Check>
              <Check
                direction="vertical"
                id={`type-${ESuggestion.ENHANCERS}`}
                type="radio"
                value={ESuggestion.ENHANCERS}
                {...register(`type`)}
              >
                {ESuggestionLabel.ENHANCERS}
              </Check>
              <Check
                direction="vertical"
                id={`type-${ESuggestion.IDEAL_SELECTION}`}
                type="radio"
                value={ESuggestion.IDEAL_SELECTION}
                {...register(`type`)}
              >
                {ESuggestionLabel.IDEAL_SELECTION}
              </Check>
            </FormGroup>
          </div>
        </div>

        <div className={css('actions')}>
          <Button type="submit" isLoading={isSaving} width="15rem">
            Guardar
          </Button>
        </div>
      </Form>
    </div>
  );
};
