import { customersService as customer } from './customers/customers.service';
import { deliveryConditionService as deliveryCondition } from './delivery-condition/delivery-condition.service';
import { distributorsService as distributors } from './distributors/distributors.service';
import { exportService as exports } from './exports/exports.service';
import { g2mFunctionsService as g2mFunctions } from './g2m-functions/g2m-functions.service';
import { groupingRulesService as groupingRules } from './grouping-rules/grouping-rules.service';
import { masterEntitiesService as masterEntities } from './master-entities/master-entities.service';
import { hierarchyService as hierarchy } from './navigation/hierarchy.service';
import { navigationService as navigation } from './navigation/navigation.service';
import { ordersService as orders } from './orders/orders.service';
import { productsService as products } from './products/products.service';
import { staticPagesService as staticPages } from './static-pages/static-pages.service';
import { suggestedProductsService as suggestedProducts } from './suggested-product/suggested-product.service';
import { customizationService as customization } from './themes/customization.service';
import { highlightedCarouselService as highlightedCarousel } from './themes/highlighted-carousel.service';
import { themeModulesService as themeModules } from './themes/theme-modules.service';

export const apiService = {
  customer,
  deliveryCondition,
  distributors,
  exports,
  g2mFunctions,
  groupingRules,
  hierarchy,
  masterEntities,
  navigation,
  orders,
  products,
  staticPages,
  suggestedProducts,
  customization,
  themeModules,
  highlightedCarousel,
};
