import type { IDisplayHighlightedCarousel } from 'core/model/interfaces/themes';
import { ConfirmationModal } from 'shared/components/confirmation-modal';

interface IDeleteConfirmationModalProps {
  onDeleteConfirmation: (id: number) => void;
  selectedItem: IDisplayHighlightedCarousel | null;
  setSelectedItem: (row: IDisplayHighlightedCarousel | null) => void;
}

export const DeleteConfirmationModal = ({
  onDeleteConfirmation,
  selectedItem,
  setSelectedItem,
}: IDeleteConfirmationModalProps) => {
  const confirmationMessage = (
    <span>
      ¿Estás seguro que deseas eliminar el carrusel de productos <b>{selectedItem?.name}</b>?
    </span>
  );

  if (selectedItem === null) return null;
  return (
    <ConfirmationModal
      head="Eliminar carrusel de productos"
      message={confirmationMessage}
      onConfirm={() => {
        onDeleteConfirmation(selectedItem.id);
        setSelectedItem(null);
      }}
      onCancel={() => setSelectedItem(null)}
    />
  );
};
