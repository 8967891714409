import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { ICustomizationFilters, IDisplayCustomization } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useCustomizationList = (filters: ICustomizationFilters) => {
  const [customizations, setThemes] = useState<Array<IDisplayCustomization>>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isCustomizationLoading, setIsCustomizationLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsCustomizationLoading(true);
    setError(null);
    apiService.customization
      .getCustomizationList({ ...filters })
      .then(response => {
        setThemes(response.themes);
        setTotalPages(response.pagination.totalPages);
      })
      .catch(error => {
        setError(error);
        notification.error(getErrorFromService(error));
      })
      .finally(() => {
        setIsCustomizationLoading(false);
      });
  }, [filters]);

  return {
    customizations,
    totalPages,
    isCustomizationLoading,
    error,
  };
};
