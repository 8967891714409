import type {
  ICustomizationSuggestedProductFormData,
  IOptionsSaveBannersForm,
  IShortcutFormData,
} from 'core/model/interfaces/themes';
import { themeModulesData } from './theme-modules.data';
import {
  bannerDTOToShortcutFormData,
  bannerListDtoToBannerListForm,
  bannerListFormToBannerListRequest,
  configSuggestedProductDTOToSuggestedProductFormData,
  moduleDTOToModuleDisplay,
  shortcutFormDataToUpdateModuleDetailDTO,
  suggestedProductFormDataToSuggestedProductConfigurationDTO,
} from './theme-modules.mappers';

export const themeModulesService = {
  getList: async (themeId: number) => moduleDTOToModuleDisplay(await themeModulesData.getList(themeId)),

  changeStatus: (themeId: number, moduleId: number, newStatus: boolean) =>
    themeModulesData.changeStatus(themeId, moduleId, newStatus),

  updateShortcuts: async (themeId: number, moduleId: number, shortcutFormData: IShortcutFormData) => {
    const banners = shortcutFormData.shortcuts.map(shortcutFormDataToUpdateModuleDetailDTO);
    await themeModulesData.updateDetail({
      themesId: themeId,
      moduleId,
      banners,
    });
  },

  getShortcuts: async (themeId: number, moduleId: number) => {
    const moduleDetail = await themeModulesData.getDetail(themeId, moduleId);
    return moduleDetail.banners.map(bannerDTOToShortcutFormData);
  },
  getBanners: async (themeId: number, moduleId: number) =>
    bannerListDtoToBannerListForm(await themeModulesData.getDetail(themeId, moduleId)),

  saveBanners: (options: IOptionsSaveBannersForm) =>
    themeModulesData.updateDetail({
      themesId: options.themeId,
      moduleId: options.moduleId,
      banners: bannerListFormToBannerListRequest(options.bannerListForm, options.uploadedImages),
    }),

  getSuggestedProduct: async (themeId: number, moduleId: number) => {
    const moduleDetail = await themeModulesData.getDetail(themeId, moduleId);
    if (moduleDetail.suggestedProductConfiguration.length > 0) {
      const formData = moduleDetail.suggestedProductConfiguration[0].config.map(
        configSuggestedProductDTOToSuggestedProductFormData,
      )[0];
      return formData;
    }
    return null;
  },

  updateSuggestedProduct: async (
    themeId: number,
    moduleId: number,
    suggestedProductFormData: ICustomizationSuggestedProductFormData,
  ) => {
    const suggestedProductConfiguration =
      suggestedProductFormDataToSuggestedProductConfigurationDTO(suggestedProductFormData);
    await themeModulesData.updateDetail({
      themesId: themeId,
      moduleId,
      suggestedProductConfiguration: [suggestedProductConfiguration],
    });
  },
};
