import type { ICustomizationFilters, IHighlightedCarouselFilters } from 'core/model/interfaces/themes';

export interface IThemeState {
  customization: {
    filters: ICustomizationFilters;
  };
  highlightedCarousel: {
    filters: IHighlightedCarouselFilters;
  };
}

export enum EFilterThemeType {
  CUSTOMIZATION = 'customization',
  HIGHLIGHTED_CAROUSEL = 'highlightedCarousel',
}

export type TUpdateThemeFilters =
  | {
      type: EFilterThemeType.CUSTOMIZATION;
      filters: Partial<ICustomizationFilters>;
    }
  | {
      type: EFilterThemeType.HIGHLIGHTED_CAROUSEL;
      filters: Partial<IHighlightedCarouselFilters>;
    };
