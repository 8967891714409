export enum ESizeThemeModuleImage {
  DEFAULT = 'default',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export enum EThemeModuleType {
  BANNER = 'Banner',
  SHORTCUT = 'Shortcut',
  SUGGESTED_PRODUCT = 'SuggestedProduct',
}

// TODO use this enum instead of EModuleType when changes in the service are done
export enum EThemeModuleTypeId {
  BANNER = 1,
  SHORTCUT = 2,
  SUGGESTED_PRODUCT = 3,
  HIGHTLIGHTED_CAROUSEL = 4,
}

export enum EThemeType {
  DEFAULT = 1,
  HIGHTLIGHTED_CAROUSEL = 2,
}
