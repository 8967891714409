import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { customizationRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import type { ICustomizationFormData } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';
import { CustomizationForm } from '../components/customization-form';

export const CreateCustomization = () => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const onSubmit = async (data: ICustomizationFormData) => {
    try {
      setIsSaving(true);
      const customizationId = await apiService.customization.create(data);

      notification.success('Nueva personalización creada exitosamente');
      setIsSaving(false);
      navigate(`${themesRoutePath}${customizationRoutePath}/${customizationId}/modulos`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  return <CustomizationForm onSubmit={onSubmit} isSaving={isSaving} />;
};
