import type { TSliceReducer } from 'core/store';
import { initialState } from './constants';
import type { EFilterThemeType, IThemeState, TUpdateThemeFilters } from './types';

const updateFilters: TSliceReducer<IThemeState, TUpdateThemeFilters> = (state, { payload }) => {
  state[payload.type].filters = {
    ...state[payload.type].filters,
    ...payload.filters,
  };
};

export const resetFilters: TSliceReducer<IThemeState, EFilterThemeType> = (state, { payload }) => {
  state[payload].filters = { ...initialState[payload].filters };
};

export const reducers = { updateFilters };
