import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type {
  IThemeDetailParams,
  IThemeGetListParams,
  IThemeGetListResponse,
  TThemeCreateDTO,
  TThemeDetailDTO,
  TThemeUpdateDTO,
} from './themes.dto';

const themesBaseURL = '/gtm-static-management/v1/themes';

export const themesData = {
  getThemesList: async (params: IThemeGetListParams): Promise<IThemeGetListResponse> => {
    const { data } = await https.get<IServiceSuccess<IThemeGetListResponse>>(themesBaseURL, {
      params,
    });
    return data.data;
  },

  deleteTheme: async (id: number): Promise<void> => {
    await https.delete(`${themesBaseURL}/${id}`);
  },

  create: async (body: TThemeCreateDTO): Promise<number> => {
    const { data } = await https.post<IServiceSuccess<{ themeId: number }>>(`${themesBaseURL}`, body);
    return data.data.themeId;
  },

  getDetail: async (params: IThemeDetailParams): Promise<TThemeDetailDTO> => {
    const { id, includeModules } = params;
    const { data } = await https.get<IServiceSuccess<TThemeDetailDTO>>(`${themesBaseURL}/${id}`, {
      params: { includeModules },
    });
    return data.data;
  },

  edit: async ({ id, ...body }: TThemeUpdateDTO): Promise<number> => {
    const { data } = await https.put<IServiceSuccess<{ themeId: number }>>(`${themesBaseURL}/${id}`, body);
    return data.data.themeId;
  },
};
