import type { IDisplayCustomization } from 'core/model/interfaces/themes';
import { ConfirmationModal } from 'shared/components/confirmation-modal';

interface IDeleteConfirmationModalProps {
  onDeleteConfirmation: (id: number) => void;
  selectedItem: IDisplayCustomization | null;
  setSelectedItem: (row: IDisplayCustomization | null) => void;
}

export const DeleteConfirmationModal = ({
  onDeleteConfirmation,
  selectedItem,
  setSelectedItem,
}: IDeleteConfirmationModalProps) => {
  const confirmationMessage = (
    <span>
      ¿Estás seguro de eliminar la personalización <b>{selectedItem?.name}</b>?
    </span>
  );

  if (selectedItem === null) return null;
  return (
    <ConfirmationModal
      head="Eliminar Personalización"
      message={confirmationMessage}
      onConfirm={() => {
        onDeleteConfirmation(selectedItem.id);
        setSelectedItem(null);
      }}
      onCancel={() => setSelectedItem(null)}
    />
  );
};
