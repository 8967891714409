import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { defaultCustomizationSuggestedProduct } from 'core/constants/customization.constants';
import type { ICustomizationSuggestedProductFormData } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useCustomizationSuggestedProduct = (customizationId: number, moduleId: number) => {
  const [formData, setFormData] = useState<ICustomizationSuggestedProductFormData>(
    defaultCustomizationSuggestedProduct,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);

    apiService.themeModules
      .getSuggestedProduct(customizationId, moduleId)
      .then(remoteCustomization => {
        if (remoteCustomization) {
          setFormData(remoteCustomization);
        }
      })
      .catch(error => {
        setError(error);
        notification.error(getErrorFromService(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [customizationId, moduleId]);

  return {
    formData,
    isLoading,
    error,
  };
};
