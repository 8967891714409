import { Button } from '@insuma/mpp-ui/components/button';
import type { IColumnProps } from '@insuma/mpp-ui/components/table';
import type { IDisplayHighlightedCarousel } from 'core/model/interfaces/themes';

export const getHighlightedCarouselColumns = (
  onEdit: (id: number) => void,
  onDelete: (row: IDisplayHighlightedCarousel) => void,
): Array<IColumnProps<IDisplayHighlightedCarousel>> => [
  {
    header: 'Nombre',
    accessor: 'name',
  },
  {
    header: 'Operador',
    accessor: 'externalDistributors',
    render: row => <span>{row.externalDistributors}</span>,
  },
  {
    header: 'Lista de precios',
    accessor: 'externalPriceLists',
    render: row => <span>{row.externalPriceLists}</span>,
  },
  {
    header: '',
    accessor: 'events',
    render: row => (
      <div className="highlighted-carousel-list__table-actions">
        <Button isIconOnly size="sm" iconName="pencil" variant="tertiary" onClick={() => onEdit(row.id)} />
        <Button isIconOnly size="sm" iconName="trash" variant="tertiary" onClick={() => onDelete(row)} />
      </div>
    ),
  },
];
