import { EThemeType } from 'core/model/enums/themes.enum';
import type { IHighlightedCarouselFormData } from 'core/model/interfaces/themes';
import {
  highlightedCarouselDTOToDisplayList,
  highlightedCarouselDTOToForm,
  highlightedCarouselFormToDTO,
} from './highlighted-carousel.mappers';
import { themesData } from './themes.data';
import type { IThemeGetListParams } from './themes.dto';

export const highlightedCarouselService = {
  getList: async (params: IThemeGetListParams) => {
    const results = await themesData.getThemesList({
      ...params,
      typeId: EThemeType.HIGHTLIGHTED_CAROUSEL,
    });

    const mappedResults = highlightedCarouselDTOToDisplayList(results);
    return mappedResults;
  },
  delete: async (id: number) => themesData.deleteTheme(id),

  getDetail: async (id: string) => {
    const response = await themesData.getDetail({ id, includeModules: true });
    if (response.typeId !== EThemeType.HIGHTLIGHTED_CAROUSEL) {
      throw new Error('The type of the theme is not a carousel of products.');
    }
    if (!('modules' in response) || response.modules.length === 0)
      throw new Error('No data found for product carousel module');
    const mappedDetail = highlightedCarouselDTOToForm(response);
    return mappedDetail;
  },
  create: async (data: IHighlightedCarouselFormData) => {
    const mappedDTO = highlightedCarouselFormToDTO(data);
    const highlightedCarouselId = await themesData.create(mappedDTO);
    return highlightedCarouselId;
  },
  edit: async (data: IHighlightedCarouselFormData, id: string) => {
    const mappedDTO = highlightedCarouselFormToDTO(data);
    const highlightedCarouselId = await themesData.edit({ ...mappedDTO, id });
    return highlightedCarouselId;
  },
};
