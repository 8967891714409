import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { highlightedCarouselRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import { IDisplayHighlightedCarousel } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { useAppDispatch, useAppSelector } from 'core/store';
import { EFilterThemeType, selectHighlightedCarousel, updateFilters } from 'core/store/themes';

export const useHighlightedCarouselListActions = () => {
  const navigate = useNavigate();
  const [itemToDelete, setItemToDelete] = useState<IDisplayHighlightedCarousel | null>(null);
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector(selectHighlightedCarousel);

  const editCarousel = (id: number) => {
    navigate(`${themesRoutePath}${highlightedCarouselRoutePath}/editar/${id}`);
  };

  const deleteCarousel = async (id: IDisplayHighlightedCarousel['id']) => {
    try {
      await apiService.highlightedCarousel.delete(id);
      dispatch(
        updateFilters({
          type: EFilterThemeType.HIGHLIGHTED_CAROUSEL,
          filters,
        }),
      );
      notification.success('Carrusel eliminado con éxito');
    } catch (error) {
      notification.error('No se pudo eliminar el Carrusel');
    }
  };

  const createCarousel = () => {
    navigate(`${themesRoutePath}${highlightedCarouselRoutePath}/crear`);
  };

  return {
    itemToDelete,
    createCarousel,
    setItemToDelete,
    editCarousel,
    deleteCarousel,
  };
};
