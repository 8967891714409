import { ESuggestion } from 'core/model/enums/suggested-product.enum';
import {
  ICustomizationFormData,
  ICustomizationSuggestedProductFormData,
  IShortcutItemFormData,
} from 'core/model/interfaces/themes';

export const defaultCustomization: ICustomizationFormData = {
  name: '',
  conditions: {
    externalDistributors: [],
    externalPriceGroups: [],
    externalPriceLists: [],
    externalZones: [],
    externalCustomers: '',
  },
  externalExcludedCustomers: '',
};

export const defaultCustomizationShortcut: IShortcutItemFormData = {
  buttonName: '',
  image: {
    url: '',
  },
  link: '',
  title: '',
};

export const defaultCustomizationSuggestedProduct: ICustomizationSuggestedProductFormData = {
  title: '',
  maxSuggestions: 0,
  type: ESuggestion.UNFORGIVABLES,
  active: true,
};
