import type { IServiceSuccess } from 'core/model/interfaces/service.interface';
import { https } from 'shared/utils/https';
import type {
  IGetResponseModuleListDTO,
  IGetThemeModuleDetailDTO,
  IModuleDTO,
  IUpdateThemeModuleDetailDTO,
} from './theme-modules.dto';

const themeModulesBaseURL = '/gtm-static-management/v1/themes';

export const themeModulesData = {
  getList: async (themeId: number): Promise<Array<IModuleDTO>> => {
    const {
      data: {
        data: { modules },
      },
    } = await https.get<IServiceSuccess<IGetResponseModuleListDTO>>(`${themeModulesBaseURL}/${themeId}/modules`);

    return modules;
  },

  changeStatus: async (themeId: number, moduleId: number, newStatus: boolean): Promise<void> => {
    await https.patch<void>(`${themeModulesBaseURL}/${themeId}/modules/${moduleId}`, { active: newStatus });
  },

  getDetail: async (themeId: number, moduleId: number): Promise<IGetThemeModuleDetailDTO> => {
    const {
      data: { data },
    } = await https.get<IServiceSuccess<IGetThemeModuleDetailDTO>>(
      `${themeModulesBaseURL}/${themeId}/modules/${moduleId}`,
    );

    return data;
  },

  updateDetail: async ({ themesId, moduleId, banners, suggestedProductConfiguration }: IUpdateThemeModuleDetailDTO) => {
    const { data } = await https.put<IServiceSuccess<void>>(`${themeModulesBaseURL}/${themesId}/modules/${moduleId}`, {
      banners,
      suggestedProductConfiguration,
    });

    return data;
  },
};
