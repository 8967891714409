import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import type { IDisplayHighlightedCarousel, IHighlightedCarouselFilters } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useHighlightedCarouselList = (filters: IHighlightedCarouselFilters) => {
  const [highlightedCarousels, setHighlightedCarousels] = useState<Array<IDisplayHighlightedCarousel>>([]);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    apiService.highlightedCarousel
      .getList(filters)
      .then(response => {
        setHighlightedCarousels(response.themes);
        setTotalPages(response.pagination.totalPages);
      })
      .catch(error => {
        setError(error);
        notification.error(getErrorFromService(error));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [filters]);

  return {
    highlightedCarousels,
    isLoading,
    totalPages,
    error,
  };
};
