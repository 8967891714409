import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@insuma/mpp-ui/components/button';
import { Icon } from '@insuma/mpp-ui/components/icon';
import { useCSS } from '@insuma/mpp-ui/hooks';
import { DEFAULT_DISTRIBUTOR_DETAIL } from 'core/constants/logistics.constants';
import { distributorsRoutePath, logisticsRoutePath } from 'core/constants/routes.constants';
import type { ICustomVisitDelivery, IDistributorForm } from 'core/model/interfaces/distributors';
import { Link } from 'shared/components/link';
import { DistributorWspPurchaseBanner } from './steps/distributor-wsp-purchase-banner';
import { schema } from './distributors-schema';
import { DistributorData, DistributorMinimumPurchase, DistributorMirrorDays, DistributorTax } from './steps';

import './distributor-form.scss';

interface IDistributorsFormProps {
  onSubmit(data: IDistributorForm): void;
  distributor: IDistributorForm;
  customVisitDelivery: Array<ICustomVisitDelivery>;
  isSaving: boolean;
}

export const DistributorsForm = ({ distributor, customVisitDelivery, isSaving, onSubmit }: IDistributorsFormProps) => {
  const defaultValues: IDistributorForm = {
    ...structuredClone(distributor || DEFAULT_DISTRIBUTOR_DETAIL),
  };
  const css = useCSS('distributors-form');
  const formMethods = useForm<IDistributorForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { handleSubmit } = formMethods;
  return (
    <div className={css()}>
      <div className={css('link-wrapper')}>
        <Link to={`${logisticsRoutePath}${distributorsRoutePath}`}>
          <Icon name="caret-left" />
          Atrás
        </Link>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className={css('title-leading')}>Operador</h2>
        <FormProvider {...formMethods}>
          <div className={css('steps')}>
            <DistributorData />
            <DistributorMirrorDays customVisitDeliveries={customVisitDelivery} />
            <DistributorMinimumPurchase />
            <DistributorWspPurchaseBanner />
            <DistributorTax />
          </div>
        </FormProvider>
        <div className={css('save')}>
          <Button type="submit" disabled={isSaving}>
            {isSaving ? 'Guardando...' : 'Guardar'}
          </Button>
        </div>
      </form>
    </div>
  );
};
