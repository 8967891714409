import { EThemeType } from 'core/model/enums/themes.enum';
import type { ICustomizationFormData } from 'core/model/interfaces/themes';
import {
  customizationDTOToDisplayList,
  customizationDTOToFormData,
  customizationFormDataToCreateCustomization,
} from './customization.mappers';
import { themesData } from './themes.data';
import type { IThemeGetListParams } from './themes.dto';

export const customizationService = {
  getCustomizationList: async (params: IThemeGetListParams) => {
    const results = await themesData.getThemesList(params);
    const mappedResults = customizationDTOToDisplayList(results);
    return mappedResults;
  },
  deleteCustomization: async (id: number) => themesData.deleteTheme(id),

  getDetail: async (id: string) => {
    const response = await themesData.getDetail({ id });
    if (response.typeId !== EThemeType.DEFAULT) {
      throw new Error('The theme type is not a default customization');
    }
    if (!('externalCustomers' in response && 'externalExcludedCustomers' in response))
      throw new Error('Incomplete default personalization data');

    const customizationFormData = customizationDTOToFormData(response);
    return customizationFormData;
  },

  create: async (body: ICustomizationFormData) => {
    const createCustomization = customizationFormDataToCreateCustomization(body);
    const customizationId = await themesData.create(createCustomization);
    return customizationId;
  },

  edit: async (id: string, body: ICustomizationFormData) => {
    const createCustomization = customizationFormDataToCreateCustomization(body);
    const customizationId = await themesData.edit({ id, ...createCustomization });
    return customizationId;
  },
};
