import { EThemeModuleTypeId, EThemeType } from 'core/model/enums/themes.enum';
import { IDisplayHighlightedCarouselList, IHighlightedCarouselFormData } from 'core/model/interfaces/themes';
import { parseDistributorId, unParseDistributorId } from 'shared/utils/distributors.utils';
import { ICreateHighlightedCarouselDTO, IHighlightedCarouselDetailDTO } from './highlighted-carousel.dto';
import { IThemeGetListResponse } from './themes.dto';

export const highlightedCarouselDTOToDisplayList = (result: IThemeGetListResponse): IDisplayHighlightedCarouselList => {
  const { results, pagination } = result;

  return {
    pagination,
    themes: results.map(result => ({
      id: result.themeId,
      name: result.name,
      externalPriceLists: result.externalPriceLists.map(ep => ep.description).join(', '),
      externalDistributors: result.externalDistributors.map(ed => ed.description).join(', '),
    })),
  };
};

export const highlightedCarouselFormToDTO = (form: IHighlightedCarouselFormData): ICreateHighlightedCarouselDTO => ({
  typeId: EThemeType.HIGHTLIGHTED_CAROUSEL,
  name: form.name,
  externalDistributors: form.conditions.externalDistributors?.map(unParseDistributorId) ?? [],
  externalPriceListIds: form.conditions.externalPriceLists ?? [],
  externalPriceGroupIds: form.conditions.externalPriceGroups ?? [],
  externalZoneIds: form.conditions.externalZones ?? [],
  modules: [
    {
      active: form.active,
      externalProductIds: form.skus.split('\n').filter(sku => sku.trim() !== ''),
      typeId: EThemeModuleTypeId.HIGHTLIGHTED_CAROUSEL,
    },
  ],
});

export const highlightedCarouselDTOToForm = (response: IHighlightedCarouselDetailDTO): IHighlightedCarouselFormData => {
  const { name, externalDistributors, externalPriceGroups, externalPriceLists, externalZones, modules } = response;
  const { active, externalProductIds } = modules[0];

  return {
    name,
    conditions: {
      externalDistributors: externalDistributors.map(ed => parseDistributorId(ed.id, ed.externalBranchId)),
      externalPriceLists,
      externalPriceGroups,
      externalZones,
    },
    active,
    skus: externalProductIds?.join('\n') ?? '',
  };
};
