import type { ICustomizationFormData, IIDisplayCustomizationList } from 'core/model/interfaces/themes';
import { parseDistributorId, unParseDistributorId } from 'shared/utils/distributors.utils';
import type { ICreateCustomizationDTO, ICustomizationDetailDTO } from './customization.dto';
import type { IThemeGetListResponse } from './themes.dto';

export const customizationDTOToDisplayList = (result: IThemeGetListResponse): IIDisplayCustomizationList => {
  const { results, pagination } = result;

  return {
    pagination,
    themes: results.map(result => ({
      id: result.themeId,
      name: result.name,
      externalPriceLists: result.externalPriceLists.map(ep => ep.description).join(', '),
      externalDistributors: result.externalDistributors.map(ed => ed.description).join(', '),
    })),
  };
};

export const customizationFormDataToCreateCustomization = (
  customization: ICustomizationFormData,
): ICreateCustomizationDTO => {
  const { name, conditions, externalExcludedCustomers } = customization;

  return {
    name,
    externalDistributors: conditions.externalDistributors?.map(unParseDistributorId) ?? [],
    externalPriceListIds: conditions.externalPriceLists ?? [],
    externalPriceGroupIds: conditions.externalPriceGroups ?? [],
    externalZoneIds: conditions.externalZones ?? [],
    externalCustomerIds: conditions.externalCustomers.split('\n').filter(ec => ec.trim() !== ''),
    externalExcludedCustomerIds: externalExcludedCustomers.split('\n').filter(eec => eec.trim() !== ''),
  };
};

export const customizationDTOToFormData = (customization: ICustomizationDetailDTO): ICustomizationFormData => {
  const {
    name,
    externalDistributors,
    externalPriceGroups,
    externalPriceLists,
    externalCustomers,
    externalExcludedCustomers,
    externalZones,
  } = customization;

  return {
    name,
    conditions: {
      externalDistributors: externalDistributors.map(ed => parseDistributorId(ed.id, ed.externalBranchId)),
      externalPriceLists,
      externalPriceGroups,
      externalZones,
      externalCustomers: externalCustomers?.join('\n') ?? '',
    },
    externalExcludedCustomers: externalExcludedCustomers?.join('\n') ?? '',
  };
};
