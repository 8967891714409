import { Control, useWatch } from 'react-hook-form';
import { Button } from '@insuma/mpp-ui/components/button';
import type { IShortcutFormData, IShortcutItemFormData } from 'core/model/interfaces/themes';

import './shortcut-item-view.scss';

interface IShortcutsItemViewProps {
  index: number;
  control: Control<IShortcutFormData>;
  formDisabled?: boolean;
  handleEditClick: (value: IShortcutItemFormData) => void;
  handleDeleteClick: () => void;
  hasErrors: boolean;
}

export const ShortcutItemView = ({
  hasErrors,
  formDisabled,
  handleEditClick,
  handleDeleteClick,
  control,
  index,
}: IShortcutsItemViewProps) => {
  const shortcut = useWatch({
    control,
    name: `shortcuts.${index}` as const,
  });

  return (
    <div className={`shortcut-item-view ${hasErrors ? 'shortcut-item-view--invalid' : ''}`}>
      <div className="shortcut-item-view__info">
        <img src={shortcut.image?.url} alt="Imagen" width={60} />
        <span>{shortcut.title}</span>
      </div>
      <div className="shortcut-item-view__actions">
        <Button
          iconName="pencil"
          hasIcon="left"
          variant="tertiary"
          disabled={formDisabled}
          onClick={() => handleEditClick({ ...shortcut })}
        >
          Editar
        </Button>
        <Button iconName="trash" hasIcon="left" variant="tertiary" disabled={formDisabled} onClick={handleDeleteClick}>
          Eliminar
        </Button>
      </div>
      {hasErrors && <span className="shortcut-item-view__error-message">Debes completar los campos correctamente</span>}
    </div>
  );
};
