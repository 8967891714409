import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { customizationRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import type { IBannerListForm } from 'core/model/interfaces/banners';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { handleErrorMessage } from 'shared/utils/errors.utils';
import { uploadImages } from 'shared/utils/images-service.utils';
import { useBanners } from '../../hooks/use-banners.hooks';
import { BannerList } from './components/banner-list/banner-list';
import { getImageArrayToService } from './customization-banners.utils';

export const CustomizationBanner = () => {
  const navigate = useNavigate();
  const { customizationId, moduleId } = useParams();
  const { bannerList, loading: loadingBanners } = useBanners(Number(customizationId), Number(moduleId));
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const onSubmit = async (formData: IBannerListForm) => {
    try {
      setIsSaving(true);
      const localImages = getImageArrayToService(formData.banners);
      const uploadedImages = await uploadImages(localImages, 'banners');

      await apiService.themeModules.saveBanners({
        themeId: Number(customizationId),
        moduleId: Number(moduleId),
        bannerListForm: formData,
        uploadedImages,
      });

      notification.success('Banners actualizados exitosamente');
      navigate(`${themesRoutePath}${customizationRoutePath}/${customizationId}/modulos`);
    } catch (error) {
      notification.error(handleErrorMessage(error));
    } finally {
      setIsSaving(false);
    }
  };

  if (loadingBanners) return <Loading />;
  return (
    <BannerList
      customizationId={Number(customizationId)}
      bannerList={bannerList}
      isSaving={isSaving}
      onSubmit={onSubmit}
    />
  );
};
