import { createSlice } from '@reduxjs/toolkit';
import { initialState, SLICE_NAMESPACE } from './constants';
import { reducers } from './reducers';

const themesSlice = createSlice({
  name: SLICE_NAMESPACE,
  initialState,
  reducers,
});

export const { updateFilters } = themesSlice.actions;

export default themesSlice.reducer;
