import type { IThemeState } from './types';

export const SLICE_NAMESPACE = 'themes';

const initialFilters = {
  q: '',
  page: 1,
  limit: 10,
};

export const initialState: IThemeState = {
  customization: {
    filters: initialFilters,
  },
  highlightedCarousel: {
    filters: initialFilters,
  },
};
