import { ImageRoutes } from 'core/constants/image-routes.constants';
import { EThemeModuleType } from 'core/model/enums/themes.enum';

export const getReferenceImagesByModule = (moduleType: EThemeModuleType): Array<string> => {
  switch (moduleType) {
    case EThemeModuleType.BANNER:
      return [ImageRoutes.BANNER_REFERENCE];
    case EThemeModuleType.SHORTCUT:
      return [ImageRoutes.SHORTCUT_REFERENCE];
    case EThemeModuleType.SUGGESTED_PRODUCT:
      return [ImageRoutes.SUGGESTED_PRODUCT_REFERENCE];
    default:
      return [];
  }
};

export const getPathToModuleList = (type: EThemeModuleType) => {
  switch (type) {
    case EThemeModuleType.BANNER:
      return 'banners';
    case EThemeModuleType.SHORTCUT:
      return 'shortcuts';
    case EThemeModuleType.SUGGESTED_PRODUCT:
      return 'productosSugeridos';
    default:
  }
};
