import { useEffect, useState } from 'react';
import { notification } from '@insuma/mpp-ui/notifications';
import { defaultBannerList } from 'core/constants/banners.constants';
import type { IBannerListForm } from 'core/model/interfaces/banners';
import { apiService } from 'core/services';
import { getErrorFromService } from 'shared/utils/https';

export const useBanners = (customizationId: number, moduleId: number) => {
  const [bannerList, setBannerList] = useState<IBannerListForm>(defaultBannerList);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchBannerList = async () => {
      setLoading(true);

      try {
        const bannerList = await apiService.themeModules.getBanners(customizationId, moduleId);
        setBannerList(bannerList);
      } catch (error) {
        setError(error);
        notification.error(getErrorFromService(error, 'Error obteniendo la lista de banners'));
      } finally {
        setLoading(false);
      }
    };

    fetchBannerList();

    return () => {
      setBannerList(defaultBannerList);
    };
  }, [customizationId, moduleId]);

  return { bannerList, loading, error };
};
