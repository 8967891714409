import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from '@insuma/mpp-ui/notifications';
import { customizationRoutePath, themesRoutePath } from 'core/constants/routes.constants';
import { ICustomFile } from 'core/model/interfaces/custom-file';
import type { IShortcutFormData } from 'core/model/interfaces/themes';
import { apiService } from 'core/services';
import { Loading } from 'shared/components/loading';
import { getErrorFromService } from 'shared/utils/https';
import { uploadImages } from 'shared/utils/images-service.utils';
import { useCustomizationShortcuts } from '../../hooks/use-customization-shortcuts.hooks';
import { ShortcutForm } from './components/shortcut-form/shortcut-form';
import { getShortcutFormDataWithImages } from './customization-shortcuts.utils';

export const CustomizationShortcuts = () => {
  const { customizationId, moduleId } = useParams();
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const { isLoading, formData } = useCustomizationShortcuts(Number(customizationId), Number(moduleId));

  const onSubmit = async (data: IShortcutFormData) => {
    try {
      setIsSaving(true);

      const localImages = data.shortcuts.map(s => s.image).filter((img): img is ICustomFile => img !== null);
      const uploadedImages = await uploadImages(localImages, 'statics');

      await apiService.themeModules.updateShortcuts(
        Number(customizationId),
        Number(moduleId),
        getShortcutFormDataWithImages(data.shortcuts, uploadedImages),
      );

      notification.success('Shortcuts actualizados exitosamente');
      setIsSaving(false);
      navigate(`${themesRoutePath}${customizationRoutePath}/${customizationId}/modulos`);
    } catch (error) {
      setIsSaving(false);
      notification.error(getErrorFromService(error));
    }
  };

  if (isLoading) return <Loading />;

  return <ShortcutForm onSubmit={onSubmit} isSaving={isSaving} shortcut={formData} />;
};
