import { Route, Routes } from 'react-router-dom';
import { customizationRoutePath, highlightedCarouselRoutePath } from 'core/constants/routes.constants';
import {
  CustomizationBanner,
  CustomizationModuleList,
  CustomizationShortcuts,
  CustomizationSuggestedProduct,
} from './customization/customization-module';
import { CreateCustomization, CustomizationList, EditCustomization } from './customization';
import { CreateHighlightedCarousel, EditHighlightedCarousel, HighlightedCarouselList } from './highlighted-carousel';

const themesRoutes = () => (
  <Routes>
    <Route path={`${customizationRoutePath}/`} element={<CustomizationList />} />
    <Route path={`${customizationRoutePath}/crear`} element={<CreateCustomization />} />
    <Route path={`${customizationRoutePath}/editar/:id`} element={<EditCustomization />} />
    <Route path={`${customizationRoutePath}/:customizationId/modulos`} element={<CustomizationModuleList />} />
    <Route
      path={`${customizationRoutePath}/:customizationId/modulos/:moduleId/shortcuts`}
      element={<CustomizationShortcuts />}
    />
    <Route
      path={`${customizationRoutePath}/:customizationId/modulos/:moduleId/banners`}
      element={<CustomizationBanner />}
    />
    <Route
      path={`${customizationRoutePath}/:customizationId/modulos/:moduleId/productosSugeridos`}
      element={<CustomizationSuggestedProduct />}
    />
    <Route path={`${customizationRoutePath}/`} element={<CustomizationList />} />
    <Route path={`${customizationRoutePath}/crear`} element={<CreateCustomization />} />
    <Route path={`${highlightedCarouselRoutePath}/`} element={<HighlightedCarouselList />} />
    <Route path={`${highlightedCarouselRoutePath}/crear`} element={<CreateHighlightedCarousel />} />
    <Route path={`${highlightedCarouselRoutePath}/editar/:id`} element={<EditHighlightedCarousel />} />
  </Routes>
);

export default themesRoutes;
